:root {
    --lightGrayBG: #EEEEEE;
    --background: #121212;
    --surface: #121212;
    --primary: #FFBB6B;
    --primaryVariant: #B8D5B8;
    --error: #BC412B;
    --secondary: #D7B49E;
    --onPrimary: #000000;
    --onSecondary: #000000;
    --onBackground: #FFFFFF;
    --onSurface: #FFFFFF;
    --onError: #000000;
    --white: #FFFFFF;
    --black: #000000;

    /* ...other colors */
  }
.react-calendar {
    width: 350px;
    max-width: 100%;
    color: var(--onSurface);
    border-radius: 8px;
    /* border: 1px solid #a0a096; */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar__tile  {
    color: var(--onSurface);
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    color: var(--primary);
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
  }
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: var(--primary);
    color: var(--onPrimary);
}
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--onSurface);
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    color: var(--onSurface)
  }

  .react-calendar__month-view__days {
    color: var(--onSurface);
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    color: var(--onSurface);
  }
  .react-calendar__month-view__days__day--weekend {
    color: var(--primary);
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--lightGrayBG);
}
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  }
  .react-calendar__tile:disabled {
    background: var(--primary);
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: var(--primary);
    color: var(--onPrimary);
    border-radius: 0px;
  }
  .react-calendar__tile--now {
    font-weight: bold;
    background: var(--primary);
    color: var(--onPrimary);
 }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    /* border-radius: 6px; */
    font-weight: bold;
    background: var(--primary);
    color: var(--onPrimary);
}
  .react-calendar__tile--hasActive {
    background: var(--primary);
    color: var(--onPrimary);
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: var(--primary);
    color: var(--onPrimary);
}
  .react-calendar__tile--active {
    background: var(--primary);
    color: var(--onPrimary);
    /* border-radius: 6px; */
    font-weight: bold;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: var(--primary);
    color: var(--onPrimary);
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background: var(--primary);
    color: var(--onPrimary);
  }  
  .react-calendar__tile--range {
    background: var(--primary);
    color: var(--onPrimary);
    border-radius: 10;
  }
  .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: var(--primary);
    color: var(--onPrimary);
  }

  .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: var(--primary);
    color: var(--onPrimary);
  }